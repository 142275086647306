.MDRender>*:first-child {
    margin-top: 0 !important;
}

[id^="braintreeDataFrame-"] {
    position: absolute;
}

/** CodeMirror fixes **/
.CodeMirror {
    font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace !important;
    font-size: 13px !important;
    height: auto !important;
    padding: 12px 0 !important;
}
.CodeMirror pre {
    padding: 0 16px 0 6px !important;
}
.CodeMirror-gutters {
    border-right: none !important;
    background-color: #fff !important;
    padding: 0 10px 0 0;
}
.CodeMirror-linenumber {
    color: #ccc !important;
}

@-webkit-keyframes GlobalNavLinkActive {
    from {
        top: 50%;
        bottom: 50%;
    }
}

@keyframes GlobalNavLinkActive {
    from {
        top: 50%;
        bottom: 50%;
    }
}

@-webkit-keyframes CategoriesItemFadeIn {
    from {
        opacity: 0;
        margin-top: 24px;
    }
    to {
        opacity: 1;
        margin-top: 3px;
    }
}

@keyframes CategoriesItemFadeIn {
    from {
        opacity: 0;
        margin-top: 24px;
    }
    to {
        opacity: 1;
        margin-top: 3px;
    }
}

@-webkit-keyframes CategoriesLoadingItem {
    from {
        margin-left: -50px;
    }
    to {
        margin-left: 500px;
    }
}

@keyframes CategoriesLoadingItem {
    from {
        margin-left: -50px;
    }
    to {
        margin-left: 500px;
    }
}


.loader,
.loader:before,
.loader:after {
    background: #009aff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    font-size: 10px;
    width: 1em;
    height: 4em;
}
.loader {
    position: relative;
    color: #009aff;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader.center {
    margin: 40px auto;
}
.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}
.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 1.5em;
}
@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

