.MDRender>*:first-child {
    margin-top: 0 !important;
}

[id^="braintreeDataFrame-"] {
    position: absolute;
}

/** CodeMirror fixes **/
.CodeMirror {
    font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace !important;
    font-size: 13px !important;
    height: auto !important;
    padding: 12px 0 !important;
}
.CodeMirror pre {
    padding: 0 16px 0 6px !important;
}
.CodeMirror-gutters {
    border-right: none !important;
    background-color: #fff !important;
    padding: 0 10px 0 0;
}
.CodeMirror-linenumber {
    color: #ccc !important;
}
